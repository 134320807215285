import findRoots from 'durand-kerner';

/**
 * Get the concentration of H3O+ with given volumes of weak acid and strong base.
 * @param {number} Vb volume of strong base added
 * @param {number} Va volume of weak acid present
 * @param {number} Mb molarity of strong base
 * @param {number} Ma molarity of weak acid
 * @param {number} Ka acid dissociation constant
 * @param {number} Kw ionization constant of water
 * @param {number} wideningFactor How much to stretch the function along the x-axis
 */
export function weakAcidTitrationH(
  Vb: number,
  Va: number,
  Mb: number,
  Ma: number,
  Ka: number,
  Kw: number,
  wideningFactor: number = 1e9
) {
  const Vt = Va + Vb;
  const coefficientA = 1;
  const coefficientB = (Mb * Vb) / Vt + Ka;
  const coefficientC = Ka * ((Mb * Vb - Ma * Va) / Vt) - Kw;
  const coefficientD = -Ka * Kw;

  const coefficients = [coefficientA, coefficientB, coefficientC, coefficientD];
  return getScaledLargestRoot(coefficients, wideningFactor);
}

/**
 * Get the concentration of H3O+ given volumes of weak base and strong acid added
 * @param {number} Va volume of weak acid present
 * @param {number} Vb volume of strong base added
 * @param {number} Ma molarity of weak acid
 * @param {number} Mb molarity of strong base
 * @param {number} Ka acid dissociation constant of conjugate acid
 * @param {number} Kw ionization constant of water
 * @param {number} wideningFactor How much to stretch the function along the x-axis
 */
export function weakBaseTitrationH(
  Va: number,
  Vb: number,
  Ma: number,
  Mb: number,
  Ka: number,
  Kw: number,
  wideningFactor: number = 1e9
) {
  return weakAcidTitrationH(Vb, Va, Mb, Ma, Ka, Kw, wideningFactor);
}

/**
 * Calculate the concentration of H3O+ given an amount of strong base added to a diprotic acid
 * @param {number} Vb Volume of base added
 * @param {number} Va Volume of acid
 * @param {number} Mb Molarity of strong base
 * @param {number} Ma Molarity of diprotic acid
 * @param {number} Ka1 First acid dissociation constant for diprotic acid
 * @param {number} Ka2 Second acid dissociation constant for diprotic acid
 * @param {number} Kw Ionization constant of water
 * @param {number} wideningFactor How much to stretch the function along the x-axis
 * @return {number|null} The concentration of H3O+ or null if none is found
 */
export function diproticAcidTitrationH(
  Vb: number,
  Va: number,
  Mb: number,
  Ma: number,
  Ka1: number,
  Ka2: number,
  Kw: number = 1e-14,
  wideningFactor: number = 1e9
): number | null {
  // Calculate coefficients
  const Vt = Va + Vb;
  const coefficientA = 1;
  const coefficientB = Ka1 + (Mb * Vb) / Vt;
  const coefficientC = -(Kw + Ka1 * ((Ma * Va - Mb * Vb) / Vt) - Ka1 * Ka2);
  const coefficientD = -(Ka1 * Kw + Ka1 * Ka2 * ((2 * Ma * Va - Mb * Vb) / Vt));
  const coefficientE = -Kw * Ka1 * Ka2;

  const coefficients = [coefficientA, coefficientB, coefficientC, coefficientD, coefficientE];
  return getScaledLargestRoot(coefficients, wideningFactor);
}

/**
 * Get the pH of a concentration of H30+ or null if the concentration is null
 * @param {number} concH
 * @return {number|null}
 */
export function getPh(concH: number): number | null {
  return concH ? -Math.log10(concH) : null;
}

/**
 * Scale an array of coefficients by a widening factor
 * @param {number[]} coefficients the polynomial coefficients with highest power first
 * @param {number} wideningFactor How much to stretch the function along the x-axis
 */
function scaleCoefficients(coefficients: number[], wideningFactor: number) {
  return coefficients.map((coefficient, i) => {
    return coefficient * wideningFactor ** -(coefficients.length - 1 - i);
  });
}

/**
 * Given the polynomial's coefficients in largest power to smallest power (constant) last, find the largest positive,
 * real root.
 * @param {number[]} coefficients
 */
function findLargestPositiveRoot(coefficients: number[]) {
  // Find the roots using the Durand-Kerner method
  // roots[0] is an array (of length n where n is the order) of the real components of the roots
  const durandRoots = findRoots([...coefficients].reverse());
  const positiveRoots = durandRoots[0].filter((root) => root >= 0);
  return positiveRoots.length ? Math.max(...positiveRoots) : null;
}

/**
 * Get the largest real root, scaled by the widening factor
 * @param {number[]} coefficients the polynomial coefficients with highest power first
 * @param {number} wideningFactor How much to stretch the function along the x-axis
 */
function getScaledLargestRoot(coefficients: number[], wideningFactor: number) {
  const scaledCoefficients = scaleCoefficients(coefficients, wideningFactor);
  const root = findLargestPositiveRoot(scaledCoefficients);
  return root ? root / wideningFactor : null;
}
